import { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/Nav/PageTitle";
import { IconCirclePlus, IconCheck, IconX, IconSearch } from '@tabler/icons-react';
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import get from "lodash/get";
import useNotifications from "../../hooks/useNotifications";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import { generateWorkspacePath } from "../../common/urlHelpers";
import { timeAgo } from "../../common/dateHelpers";

const Decisions = () => {
    const { addNotification } = useNotifications();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.has('page') ? searchParams.get('page') * 1 : 0);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const workspacePath = generateWorkspacePath();
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setPage(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    }, [searchParams]);

    useEffect(() => {
        searchDecisions();
    }, [page, addNotification]);

    const searchDecisions = (event) => {
        if (event && event.type === "click") {
            setPage(0);
        }
        let url = `${workspacePath}/transformations?page=${page}&size=15&type=DECISION`;
        if (searchQuery) {
            url = url + `&name=${searchQuery}`;
        }
        axios.get(url).then(response => {
            setTotalCount(response.data && response.data.count ? response.data.count : 0);
            setData(response.data && response.data.list ? response.data.list : []);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while fetching Decisions'),
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return <Fragment>
        <PageTitle itemKey="decision">
            <Link to="/decisions/new" className="btn btn-primary btn-sm md:btn-md"><IconCirclePlus size={24} />New Decision Block</Link>
        </PageTitle>
        {loading ? <Loading /> :
            (<div>
                <div>
                    <div className="flex justify-between items-center mb-4">
                        <div className="input-group">
                            <input type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search Decisions by name" className="input input-bordered w-full" />
                            <button className="btn btn-square tooltip p-3" data-tip="Search Decision" onClick={e => { searchDecisions(e) }}>
                                <IconSearch width={24} />
                            </button>
                        </div>
                    </div>
                </div> <div className="overflow-x-auto">
                    <div className="table-container">
                        {totalCount > 0 ? <table className="table data-table table-zebra w-full">
                            <thead>
                                <tr>
                                    <th className="bg-base-300 text-left w-2/3">Name</th>
                                    <th className="bg-base-300 text-center">Active</th>
                                    <th className="bg-base-300 text-center">Created</th>
                                    <th className="bg-base-300 text-center">Last Modified</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(decision => <tr key={decision.id}>
                                    <td className="whitespace-pre-wrap break-words"><Link className="link link-hover" to={`/decisions/${decision.id}`}>{decision.name}</Link></td>
                                    <td className="text-center"><div className="flex justify-center">{decision.active ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div></td>
                                    <td className="text-center break-words"><div className="tooltip" data-tip={`${Date(decision.createdAt).toLocaleString()}`} >{timeAgo(decision.createdAt)}</div></td>
                                    <td className="text-center break-words"><div className="tooltip" data-tip={`${Date(decision.updatedAt).toLocaleString()}`} >{timeAgo(decision.updatedAt)}</div></td>                                
                                </tr>)}
                            </tbody>
                        </table> : renderNoDecisions()}
                    </div>
                    {totalCount > 0 && <Pagination totalCount={totalCount} />}
                </div></div>)
        }
    </Fragment>
}

const renderNoDecisions = () => {
    return <div className="flex flex-col justify-center my-5">
        <div className="flex justify-center mb-4 text-primary">
            <img alt="Create new Decision Block" src="/undraw/coding.svg" className="w-3/6 max-w-3/5" />
        </div>
        <div className="flex justify-center mb-4 text-center">
            There are no Decision Blocks. Click the button below to add a new Decision Block
        </div>
        <div className="flex justify-center">
            <Link to="/decisions/new" className="btn btn-md md:btn-lg"><IconCirclePlus size={36} />New Decision Block</Link>
        </div>
    </div>
}


export const breadcrumbs = [{ label: 'Decisions', url: '/decisions' }];

export default Decisions;