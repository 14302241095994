import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import get from 'lodash/get';

import PageTitle from "../../../components/Nav/PageTitle";
import Loading from "../../../components/Loading";
import Tabs from "../../../components/Tabs";
import ContextHelp from "../../../components/ContextHelp";
import useNotifications from "../../../hooks/useNotifications";
import { generateWorkspacePath } from "../../../common/urlHelpers";
import { breadcrumbs } from "..";
import GlobalVariableDetailsCard from './GlobalDetailsCard';


const tabs = [{ 'key': 'overview', label: 'Overview' }];

const GlobalVariableDetails = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { id, tab } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const workspacePath = generateWorkspacePath();
    axios.get(`${workspacePath}/globalvariable/${id}`)
      .then(response => {
        setData(response.data);
      })
      .catch(err => {
        addNotification({
          message: get(err, 'response.data.message', 'An error occurred while fetching Global Variable'),
          type: 'error'
        });
        navigate('/global-variables');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, addNotification, navigate]);

  if (loading) {
    return <Loading />;
  }

  const onTabChange = (tab) => {
    navigate(`/global-variables/${id}/${tab}`);
  };

  const onClone = () => {
    navigate(`/global-variables/new?cloneFrom=${id}`);
  };

  return (
    <div>
      <PageTitle itemKey="globalvariables" label={data.name} breadcrumbs={breadcrumbs} clone={onClone} />
      <Tabs tabs={tabs} activeTab={tab} onTabChange={onTabChange} />
      {(!tab || tab === 'overview') && (
        <div className="grid grid-flow-row-dense grid-cols-1 xl:grid-cols-8 gap-4 ">
          <div className="xl:col-span-6">
            <GlobalVariableDetailsCard data={data} onUpdate={setData} />
          </div>
          <div className="hidden xl:col-span-2 xl:block mt-4">
            <ContextHelp page="global-variable-details" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalVariableDetails;
