import { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/Nav/PageTitle";
import { IconCirclePlus, IconCheck, IconX, IconSearch } from '@tabler/icons-react';
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import get from "lodash/get";
import useNotifications from "../../hooks/useNotifications";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import useStatusFilter from "../../hooks/useStatusFilter";
import { renderNoMatchingRecordsWithFilter } from "../../common/tableHelpers";
import { generateWorkspacePath } from "../../common/urlHelpers";
import { timeAgo } from "../../common/dateHelpers";


const Sources = () => {
    const { addNotification } = useNotifications();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    const [status, setStatus] = useState(searchParams.has('status') ? searchParams.get('status') : null);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setPage(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    }, [searchParams]);

    useStatusFilter({ status });

    useEffect(() => {
        searchSources();
    }, [page, status, addNotification]);

    const searchSources = (event) => {
        const workspacePath = generateWorkspacePath();
        if (event && event.type === "click") {
            setPage(0);
        }
        let url = `${workspacePath}/sources?page=${page}&size=15`;
        if (searchQuery) {
            url = url + `&name=${searchQuery}`;
        }
        const filters = [];
        if (status) {
            filters.push(`state:eq:${status}`);
        }

        if (filters.length > 0) {
            url += `&filters=${filters.join()}`;
        }

        axios.get(url).then(response => {
            setTotalCount(response.data && response.data.count ? response.data.count : 0);
            setData(response.data && response.data.list ? response.data.list : []);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while fetching Sources'),
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const renderNoRecordsToShow = () => {
        if (status) {
            return renderNoMatchingRecordsWithFilter('Source');
        }
        return renderNoSources();
    }

    return <Fragment>
        <PageTitle itemKey="sources">
                <Link to="/sources/new" className="btn btn-primary btn-sm md:btn-md"><IconCirclePlus size={24} />New Source</Link>
        </PageTitle>
        {loading ? <Loading /> :
            (<div>
                <div>
                    <div className="flex justify-between items-center mb-4">
                        <div className="input-group">
                            <input type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search Sources by name" className="input input-bordered w-full" />
                            <button className="btn btn-square tooltip p-3" data-tip="Search Source" onClick={e => { searchSources(e) }}>
                                <IconSearch width={24} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <div className="table-container">
                        {totalCount > 0 ? <table className="table data-table table-zebra w-full">
                            <thead>
                                <tr>
                                    <th className="bg-base-300 text-left w-1/2 md:w-2/3">Name</th>
                                    <th className="bg-base-300 text-center">Secured</th>
                                    <th className="bg-base-300 text-center">Active</th>
                                    <th className="bg-base-300 text-center">Created</th>
                                    <th className="bg-base-300 text-center">Last Modified</th>
                                </tr>
                            </thead>
                            <tbody style={{ maxHeight: '600px' }}>
                                {data.map(source => <tr key={source.id}>
                                    <td className="whitespace-pre-wrap break-words"><Link className="link link-hover"
                                        to={`/sources/${source.id}`}>{source.name}</Link>
                                    </td>
                                    <td className="text-center">
                                        <div className="flex justify-center">{source.secured ?
                                            <IconCheck className="text-success" size={24} /> :
                                            <IconX className="text-error" size={24} />}</div>
                                    </td>
                                    <td className="text-center">
                                        <div className="flex justify-center">{source.active ?
                                            <IconCheck className="text-success" size={24} /> :
                                            <IconX className="text-error" size={24} />}</div>
                                    </td>
                                    <td className="text-center break-words"><div className="tooltip" data-tip={`${Date(source.createdAt).toLocaleString()}`} >{timeAgo(source.createdAt)}</div></td>
                                    <td className="text-center break-words"><div className="tooltip" data-tip={`${Date(source.updatedAt).toLocaleString()}`} >{timeAgo(source.updatedAt)}</div></td>
                                </tr>)}
                            </tbody>
                        </table> : renderNoRecordsToShow()}
                    </div>
                    {totalCount > 0 && <Pagination totalCount={totalCount} />}
                </div></div>)
        }
    </Fragment>
}

const renderNoSources = () => {
    return <div className="flex flex-col justify-center my-5">
        <div className="flex justify-center mb-4 text-primary">
            <img alt="Create new Source" src="/undraw/road_sign.svg" className="w-3/6 max-w-3/5" />
        </div>
        <div className="flex justify-center mb-4 text-center">
            There are no Sources. Click the button below to add a new Data Source
        </div>
        <div className="flex justify-center">
            <Link to="/sources/new" className="btn btn-md md:btn-lg"><IconCirclePlus size={36} />New
                Source</Link>
        </div>
    </div>
}

export const breadcrumbs = [{ label: 'Sources', url: '/sources' }];

export default Sources;