import { Link, useLocation } from "react-router-dom";
import Logo from "../Logo";
import { navItems } from "../data";
import { IconChevronLeft } from "@tabler/icons-react";


const LeftNav = ({isDrawerOpen, toggleDrawer}) => {
    const location = useLocation();
    
    const drawerWidth = "240px";
    const miniDrawerWidth = "64px";

    return <div
        className={`flex flex-col min-h-screen bg-base-200 shadow-lg transition-all duration-300 ${isDrawerOpen ? `w-[${drawerWidth}]` : `w-[${miniDrawerWidth}]`
            }`}
    >
        <label htmlFor="page-drawer" className="drawer-overlay"></label>
        <aside className={`bg-base-200 ${isDrawerOpen ? "w-80" : ""} h-full hidden md:block`}>
            <div className={`z-20 backdrop-blur sticky top-0 items-center gap-2 ${isDrawerOpen ? "px-4" : "px-1"} py-2 hidden lg:flex `}>
                <div className={`transition-opacity duration-500 ${isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}>
                    {isDrawerOpen ? <Logo /> : ""}
                </div>
                <button className="btn btn-ghost ml-auto" onClick={toggleDrawer}>
                    <IconChevronLeft className={`transition-transform duration-500 ${isDrawerOpen ? "rotate-0" : "rotate-180"}`} />
                </button>
            </div>
            <ul className={`menu flex flex-col p-0 ${isDrawerOpen ? "px-4" : "px-1 items-center"}`}>
                {navItems.map((item) => <li key={item.label} className={`flex gap-4 ${isDrawerOpen ? "" : "w-fit items-center"}`}>
                    <Link aria-label={item.label} to={item.url} className={location.pathname.startsWith(item.url) ? 'active' : ''}><item.Icon size={24} />{isDrawerOpen ? item.label : ""}</Link>
                </li>)}
            </ul>
        </aside>
    </div>
}

export default LeftNav;