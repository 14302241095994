import axios from "axios";
import { useEffect, Fragment, useState } from "react";
import Loading from "../Loading";
import get from "lodash/get";
import ReactDOM from "react-dom";
import useNotifications from "../../hooks/useNotifications";
import Pagination from "../Pagination";
import { IconCheck, IconX, IconEye } from '@tabler/icons-react';
import ConfigurationDetailsCard from "../../pages/Configuration/ConfigurationDetails/ConfigurationDetailsCard";

const renderColData = (val) => {
    if (typeof val === 'boolean') {
        return <div className="flex justify-center items-center">{val ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>;
    } if (typeof val !== 'string') {
        return <div className="flex justify-center items-center">{JSON.stringify(val)}</div>;
    }
    return val;
}

const ItemSelectorModal = (props) => {
    const { addNotification } = useNotifications();
    const { title, url, cols, onCancel, onSelect } = props;
    const [opened, setOpened] = useState(true);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [previewMode, setPreviewMode] = useState(false);

    const renderRow = (item, cols) => {
        const { id, name } = item;
        return (
            <tr key={id}>
                {cols.map((col, i) => {
                    return (
                        <td key={col.name} className={`whitespace-pre-wrap break-words ${col.datacss}`}>
                            {i === 0 ? (
                                <button onClick={() => onSelect(item)}>{renderColData(item[col.name])}</button>
                            ) : (
                                renderColData(item[col.name])
                            )}
                        </td>
                    );
                })}
            </tr>
        );
    };

    const showDetails = (item) => {
        setSelectedItem(item);
        setPreviewMode(true); // Entering preview mode
    };

    const hideDetails = () => {
        setSelectedItem(null);
        setPreviewMode(false); // Exiting preview mode
    };

    useEffect(() => {
        let urlWithParam = "";
        if (url.indexOf("?") > -1) {
            urlWithParam = `${url}&page=${page}`;
        } else {
            urlWithParam = `${url}?page=${page}`;
        }
        axios.get(urlWithParam).then(response => {
            setTotalCount(response.data && response.data.count ? response.data.count : 0);
            setData(response.data && response.data.list ? response.data.list : []);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while fetching data'),
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [url, page, addNotification]);

    useEffect(() => {
        const close = (e) => {
            if (e.key === 'Escape') {
                onCancel();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [onCancel]);

    useEffect(() => {
        if (!opened) {
            onCancel();
        }
    }, [opened, onCancel]);

    return ReactDOM.createPortal(
        <Fragment>
            <input type="checkbox" id="my-modal-4" defaultChecked={opened} onChange={(e) => setOpened(e.target.checked)} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative w-11/12 max-w-3xl">
                    <label htmlFor="my-modal-4" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    {!previewMode && <h3 className="font-bold text-lg">{title}</h3>}
                    {loading ? (
                        <Loading />
                    ) : (
                        <Fragment>
                            {previewMode ? (
                                <div className="form-control w-full">
                                    <label className="label">
                                        <div className="text-lg font-bold">
                                            {previewMode ? "Preview" : "Configuration Details"}
                                        </div>
                                    </label>
                                    <div className="p-1 text-lg">
                                        <ConfigurationDetailsCard data={selectedItem} isPreviewMode={previewMode} />
                                    </div>
                                    <div className="px-4 py-3 text-right sm:px-6">
                                        <button
                                            type="button"
                                            onClick={hideDetails}
                                            className="btn btn-primary">
                                            Exit Preview
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="mt-3" style={{ height: "325px", overflowY: "scroll" }}>
                                        <table className="table table-compact table-zebra w-full">
                                            <thead>
                                                <tr>
                                                    {cols.map(col => {
                                                        return <th key={col.name} className={`bg-base-300 ${col.css}`}>{col.label}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, i) => renderRow(item, cols))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination currentPage={page} setCurrentPage={setPage} totalCount={totalCount} pageSize={10}/>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>,
        document.querySelector("#modal")
    );
};

export default ItemSelectorModal;
