import { isValid } from "date-fns";

export const onSecondsReaderHelper = (val) => {
    const days = Math.floor(val / (24 * 60));
    const hours = Math.floor((val % (24 * 60)) / 60);
    const remainingMin = val % 60;
    let result = ''
    if (days > 0) { result += `${days} day${days > 1 ? 's' : ''}` }
    if (hours > 0) { result += `${result ? ' ' : ''}${hours} hour${hours > 1 ? 's' : ''}` }
    if (remainingMin > 0) { result += `${result ? ' ' : ''}${remainingMin} minute${remainingMin > 1 ? 's' : ''}` }

    return result.trim()

}
export const timeAgo = (input) => {


    let date
    // Check if input is a Date or string
    if (date instanceof Date) {
        date = input
    } else if (typeof input === "string") {
        date = new Date(input);
        if (isNaN(date.getTime())) {
            return "-";
        }
    } else {
        return "--";
    }

    const now = new Date();
    const diffInMs = now - date;
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) {
        return "just now";
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else {
        return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }
}

export const isValidDate = (variable) => {
    if (variable instanceof Date && !isNaN(variable.getTime())) {
        return true
    }
    return false

}

export const relativeTimeToSeconds = ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
    return (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;
};

export const secondsToRelativeTime = (totalSeconds) => {
    const days = Math.floor(totalSeconds / 86400);
    totalSeconds %= 86400;

    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return { days, hours, minutes, seconds };
};