import { useCallback, useEffect, useState, Fragment } from "react";
import axios from "axios";
import useNotifications from "../../../hooks/useNotifications";
import get from 'lodash/get';
import filter from 'lodash/filter';
import { renderErrors } from "../../../common/formHelpers";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import ItemSelectorModal from "../../../components/ItemSelectorModal";
import Modal from "../../../components/Modal";
import NewConfiguration from "../../Configuration/NewConfiguration";
import { generateWorkspacePath } from "../../../common/urlHelpers";
import ScriptLanguageSelector from "../../../components/ScriptLanguageSelector";
import { mainScriptTemplate, modScriptTemplate, pythonFunctionTemplate, pythonMainScriptTransformationTemplate, pythonRequirementsTemplate } from "../../../common/scripts";
import Loading from "../../../components/Loading";
import ConfigBuilder from "../../../components/ConfigBuilder";
import { HTTPMethods } from "../FunctionDetails/CopyUrl";

const scriptTemplate = `module.exports = function (req, res, config) {
    //add you api logic here

    //remember to return the response
    return res.json({message: "hello world."});
}`;

const CreateFunction = (props) => {
    const { onCancel, onSuccess, noShadow = false } = props;
    const { addNotification } = useNotifications();

    const [name, setName] = useState('');
    const [secured, setSecured] = useState(true);
    const [active, setActive] = useState(true);
    const [script, setScript] = useState(scriptTemplate);
    const [modScript, setModScript] = useState(modScriptTemplate);
    const [mainScript, setMainScript] = useState(mainScriptTemplate);
    const [pythonRequirements, setPythonRequirements] = useState(pythonRequirementsTemplate);
    const [pythonMainScript, setPythonMainScript] = useState(pythonFunctionTemplate);
    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [headers, setHeaders] = useState([{ key: "", value: "" }]);
    const [expandEditor, setExpandEditor] = useState(false);
    const [captureRemoteIP, setCaptureRemoteIP] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [showConfigurationSelector, setShowConfigurationSelector] = useState(false);
    const [showConfigurationDialog, setShowConfigurationDialog] = useState(false);
    const workspacePath = generateWorkspacePath();
    const [selectedLanguage, setSelectedLanguage] = useState('javascript');
    const [version, setVersion] = useState(null);
    const configPath = `${workspacePath}/configurations?type=APIAccess`
    const [loading, setLoading] = useState(false);
    const [httpMethod, setHttpMethod] = useState('GET');
    const [config, setConfig] = useState([{ key: "", value: "", secret: false }]);

    const resetAll = () => {
        setName('');
        setSecured(true);
        setActive(true);
        setPythonMainScript(pythonMainScriptTransformationTemplate);
        setPythonRequirements(pythonRequirementsTemplate);
        clearErrors();
        setHeaders([{ key: "", value: "" }]);
        setCaptureRemoteIP(false);
    }

    const clearErrors = () => {
        setError({ message: null, details: [] });
    }

    const onConfigurationSelection = (item) => {
        setConfiguration(item);
        setShowConfigurationSelector(false);
    }


    const onCreateDestination = (item) => {
        setConfiguration(item);
        setShowConfigurationSelector(false)
    }

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);

        if (!name) {
            addNotification({
                message: 'Enter Function Name',
                type: 'error'
            });
            setExecuting(false);
            return;
        }

        const configurationId = configuration?.id || ''

        if (!configurationId) {
            addNotification({
                message: 'Select configuration',
                type: 'error'
            });
            setExecuting(false);
            return;
        }


        const payload = {
            name,
            secured,
            active,
            headers: filter(headers, item => item.key),
            captureRemoteIP,
            httpMethod,
            apiAccessRef: configurationId,
            config: filter(config, item => item.key),
            language: selectedLanguage.toLowerCase(),
            templateId: version
        };

        switch (selectedLanguage) {
            case 'javascript':
                payload.script = script;
                break;
            case 'golang':
                payload.golang = {
                    main: mainScript,
                    mod: modScript
                }
                break;
            case 'python':
                payload.python = {
                    main: pythonMainScript,
                    requirements: pythonRequirements
                }
                break;
        }

        setLoading(true);

        axios.post(`${workspacePath}/functions`, payload).then(response => {
            resetAll();
            let message, type
            if (payload.golang) {
                message = 'Function has been created and build is in progress.'
                type = 'warning'
            } else {
                message = 'Function has been created.';
                type = 'success'
            }

            addNotification({
                message: message,
                type: type
            });
            onSuccess(response.data);
        }).catch(err => {
            let message = 'An error occurred while creating Function';
            if (err.status === 409) {
                message = "A Function with this name already exists. Please try with a different name.";
            }
            addNotification({
                message: get(err, 'response.data.message', message),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setLoading(false);
            setExecuting(false);
        })
    };

    return <Fragment>
        {loading ? (
            <Loading />
        ) : (
            <form onSubmit={submit}>
                <div className={`sm:overflow-hidden sm:rounded-md ${noShadow ? '' : 'shadow-inner'}`}>
                    <div className="space-y-3 px-4 py-5 sm:p-6">

                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Function Name</span>
                            </label>
                            <input type="text" placeholder="Provide a name for the Function" value={name} className="input input-bordered w-full" onChange={e => setName(e.target.value)} />
                            {renderErrors(error, 'name')}
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Select APIAccess Configuration</span>
                            </label>
                            <div className="input-group">
                                <input type="text" readOnly disabled placeholder="Select" value={configuration?.name || ''} className="input input-bordered" />
                                <button className="btn btn-square tooltip p-3" data-tip="Select APIAccess Configuration" onClick={e => { e.preventDefault(); setShowConfigurationSelector(true) }}>
                                    <IconSearch width={24} />
                                </button>

                                <button className="btn btn-square tooltip p-2" data-tip="Create APIAccess Configuration" onClick={e => { e.preventDefault(); setShowConfigurationDialog(true) }}>
                                    <IconPlus width={24} />
                                </button>

                            </div>
                            {renderErrors(error, 'configuration')}
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Active</span>
                            </label>
                            <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={active} onChange={(e) => setActive(e.target.checked)} />
                            {renderErrors(error, 'active')}
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Capture Remote IP</span>
                            </label>
                            <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={captureRemoteIP} onChange={(e) => setCaptureRemoteIP(e.target.checked)} />
                            {renderErrors(error, 'captureRemoteIP')}
                        </div>
                        <div className="space-y-3 px-0 py-5">
                            <div className="form-control w-1/2">
                                <label className="label text-left tooltip tooltip-right" data-tip="Specifies the HTTP method used to call the function URL">
                                    <span className="label-text">HTTP Method</span>
                                </label>
                                <select
                                    className="input input-bordered w-full select select-bordered"
                                    id="httpMethod"
                                    value={httpMethod}
                                    onChange={(e) => setHttpMethod(e.target.value)}
                                >
                                    <option value="" disabled>Choose an HTTP method</option>
                                    {HTTPMethods.map((method) => (
                                        <option key={method} value={method}>
                                            {method}
                                        </option>
                                    ))}
                                </select>
                                {renderErrors(error, 'httpMethod')}
                            </div>
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Configuration</span>
                            </label>
                            <ConfigBuilder config={config} setConfig={setConfig} />
                            {renderErrors(error, 'config')}
                        </div>

                        <ScriptLanguageSelector
                            type="faas"
                            script={script}
                            setScript={setScript}
                            mainScript={mainScript}
                            setMainScript={setMainScript}
                            modScript={modScript}
                            setModScript={setModScript}
                            pythonMainScript={pythonMainScript}
                            setPythonMainScript={setPythonMainScript}
                            requirements={pythonRequirements}
                            setRequirements={setPythonRequirements}
                            expandEditor={expandEditor}
                            setExpandEditor={setExpandEditor}
                            error={error}
                            addNotification={addNotification}
                            workspacePath={workspacePath}
                            editMode={true}
                            selectedLanguage={selectedLanguage}
                            version={version}
                            setSelectedLanguage={setSelectedLanguage}
                            setVersion={setVersion}
                        />
                    </div>
                    <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                        <button onClick={onCancel} className="btn btn-ghost mr-2">Cancel</button>
                        <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
                {expandEditor && (
                    <Modal large={true} title="Script" onCancel={() => setExpandEditor(false)}>
                        <>
                            <div className="px-6 pb-4">

                                <ScriptLanguageSelector
                                    type="faas"
                                    script={script}
                                    setScript={setScript}
                                    mainScript={mainScript}
                                    setMainScript={setMainScript}
                                    modScript={modScript}
                                    setModScript={setModScript}
                                    pythonMainScript={pythonMainScript}
                                    setPythonMainScript={setPythonMainScript}
                                    requirements={pythonRequirements}
                                    setRequirements={setPythonRequirements}
                                    expandEditor={expandEditor}
                                    setExpandEditor={setExpandEditor}
                                    error={error}
                                    addNotification={addNotification}
                                    workspacePath={workspacePath}
                                    editMode={true}
                                    selectedLanguage={selectedLanguage}
                                    version={version}
                                    setSelectedLanguage={setSelectedLanguage}
                                    setVersion={setVersion}
                                />

                            </div>
                            <div className="bg-base-200 px-4 py-3 justify-end sm:px-6 flex">
                                <button className="btn" onClick={() => setExpandEditor(false)}>Collapse</button>
                            </div>
                        </>
                    </Modal>
                )}
            </form>)}
        {showConfigurationSelector && <ItemSelectorModal url={configPath} title="Select a Configuration" cols={[{ "label": "Name", "datacss": "text-left", "css": "w-1/2", "name": "name" }, { "label": "Type", "datacss": "", "css": "w-1/2", "name": "type" }]}
            onCancel={() => setShowConfigurationSelector(false)} onSelect={onConfigurationSelection} />}
        {showConfigurationDialog && <Modal title="Create Configuration" onCancel={() => setShowConfigurationDialog(false)}>
            <NewConfiguration noShadow={true} onSuccess={onCreateDestination} onCancel={() => setShowConfigurationDialog(false)} /></Modal>}
    </Fragment>

}

export default CreateFunction;