import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";

const RelativeTimePicker = ({ relativeTime ={ days: 0, hours: 0, minutes: 0, seconds: 0 }, setRelativeTime }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  // const [relativeTime, setRelativeTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  const buttonRef = useRef(null); 
  const pickerRef = useRef(null); 

  useEffect(() => {
    if (isPickerOpen) {
      createPopper(buttonRef.current, pickerRef.current, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, 8] },
          },
        ],
      });
    }

    const handleClickOutside = (event) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPickerOpen]);

  const togglePicker = (e) => {
    e.preventDefault();
    setIsPickerOpen((prev) => !prev);
  };

  const timeMapping = {
    "1m": { days: 0, hours: 0, minutes: 1, seconds: 0 },
    "5m": { days: 0, hours: 0, minutes: 5, seconds: 0 },
    "15m": { days: 0, hours: 0, minutes: 15, seconds: 0 },
    "1day": { days: 1, hours: 0, minutes: 0, seconds: 0 },
    "7day": { days: 7, hours: 0, minutes: 0, seconds: 0 },
    "14day": { days: 14, hours: 0, minutes: 0, seconds: 0 },
    "30day": { days: 30, hours: 0, minutes: 0, seconds: 0 },
    "60day": { days: 60, hours: 0, minutes: 0, seconds: 0 },
    "90day": { days: 90, hours: 0, minutes: 0, seconds: 0 },
    "suggestions": ["1m","5m","15m","1day","7day","14day","30day","60day","90day"]
  };
  const applySuggestion = (suggestion) => {
    setRelativeTime(timeMapping[suggestion]);
    setIsPickerOpen(false);
  };

  const handleChange = (field, value) => {
    setRelativeTime((prev) => ({ ...prev, [field]: Math.max(0, parseInt(value) || 0) }));
  };

  const getDisplayTime = (words = false) => {
    const fields = [];
    if (relativeTime.days) fields.push(`${relativeTime.days}${ words? 'days':'d'}`);
    if (relativeTime.hours) fields.push(`${relativeTime.hours}${ words? 'hours':'h'}`);
    if (relativeTime.minutes) fields.push(`${relativeTime.minutes}${ words? 'minutes':'m'}`);
    if (relativeTime.seconds) fields.push(`${relativeTime.seconds}${ words? 'seconds':'s'}`);
    return fields.join(" ") || "0s";
  };

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        onClick={togglePicker}
        className="btn btn-outline w-48 text-center tooltip hover:bg-transparent hover:text-inherit hover:shadow-none"
      data-tip={getDisplayTime(true)}
      >
        {getDisplayTime()}
      </button>

      {isPickerOpen && (
        <div
          ref={pickerRef}
          className="bg-base-100 shadow-lg border rounded-md z-50 w-70 p-5"
        >
          {/* Suggestions */}
          <div className="flex space-x-2 mb-4">
            {timeMapping.suggestions.map((suggestion) => (
              <button
                key={suggestion}
                onClick={() => applySuggestion(suggestion)}
                className="btn btn-xs btn-outline"
              >
                {suggestion}
              </button>
            ))}
          </div>

          {/* Fields */}
          <div className="grid grid-cols-4 gap-2">
            <div>
              <label className="block text-sm font-bold">Days</label>
              <input
                type="number"
                min="0"
                value={relativeTime.days}
                onChange={(e) => handleChange("days", e.target.value)}
                className="input input-bordered w-20"
              />
            </div>
            <div>
              <label className="block text-sm font-bold">Hours</label>
              <input
                type="number"
                min="0"
                value={relativeTime.hours}
                onChange={(e) => handleChange("hours", e.target.value)}
                className="input input-bordered w-20"
              />
            </div>
            <div>
              <label className="block text-sm font-bold">Minutes</label>
              <input
                type="number"
                min="0"
                value={relativeTime.minutes}
                onChange={(e) => handleChange("minutes", e.target.value)}
                className="input input-bordered w-20"
              />
            </div>
            <div>
              <label className="block text-sm font-bold">Seconds</label>
              <input
                type="number"
                min="0"
                value={relativeTime.seconds}
                onChange={(e) => handleChange("seconds", e.target.value)}
                className="input input-bordered w-20"
              />
            </div>
          </div>

          <button
            onClick={() => setIsPickerOpen(false)}
            className="btn btn-primary mt-4 w-full"
          >
            Select
          </button>
        </div>
      )}
    </div>
  );
};

export default RelativeTimePicker;
