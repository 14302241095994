import { useEffect, useState } from "react";

const useLeftNavCollapse = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const toggleDrawer = () => {
        localStorage.setItem("left-nav-collapse-state", !isDrawerOpen)
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        const drawerState = localStorage.getItem("left-nav-collapse-state")
        if (drawerState && drawerState != "") {
            setIsDrawerOpen(drawerState == "true" ? true : false)
        }
    }, [])

    return { isDrawerOpen, toggleDrawer} 
};

export default useLeftNavCollapse;
