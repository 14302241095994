import { IconCheck, IconX } from '@tabler/icons-react';
import { Fragment, useState, useRef, useEffect } from "react";
import useNotifications from '../../../hooks/useNotifications';
import { renderErrors } from "../../../common/formHelpers";
import get from 'lodash/get';
import keys from 'lodash/keys';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import { generateWorkspacePath } from '../../../common/urlHelpers';

const ConfigurationDetailsCard = (props) => {
    const { data: { id, name, description, active, config, type, key, value, apiAccess }, isPreviewMode, isCompactMode, onUpdate } = props; const navigate = useNavigate();
    const editorRef = useRef(null);
    const { addNotification } = useNotifications();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [changes, setChanges] = useState({});
    const [executing, setExecuting] = useState(false);
    const readOnly = true;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [expandEditor, setExpandEditor] = useState(false);

    // const handleEditorWillMount = (monaco) => {

    //     monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    //         target: monaco.languages.typescript.ScriptTarget.ES6,
    //         allowNonTsExtensions: true,
    //         allowJs: true
    //     });

    //     monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
    // }


    // const handleEditorDidMount = (editor, monaco) => {
    //     editorRef.current = editor;
    // }

    // const handleEditorChange = (value) => {
    //     setChanges(current => ({
    //         ...current,
    //     }))
    // }


    const saveChanges = (e) => {
        e.preventDefault();

        let payload = props.data;
        keys(payload).forEach(key => {
            if (changes[key]) {
                payload[key] = changes[key];
            }
        })
        setExecuting(true);
        const workspacePath = generateWorkspacePath();
        axios.put(`${workspacePath}/configurations/${id}`, payload).then(response => {
            onUpdate(response.data);
            setEditMode(false);
            addNotification({
                message: 'Configuration has been updated',
                type: 'success'
            });
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while updating Configuration'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    }

    const deleteTransformer = (e) => {
        e.preventDefault();
        setExecuting(true);
        const workspacePath = generateWorkspacePath();
        axios.delete(`${workspacePath}/configurations/${id}`).then(response => {
            addNotification({
                message: 'Configuration has been deleted',
                type: 'success'
            });
            navigate('/configurations');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while deleting Configuration'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        })
    }

    useEffect(() => {
        setChanges(current => ({
            ...current,
        }));
    }, []);

    const cancelChanges = (e) => {
        e.preventDefault();
        setChanges({});
        setEditMode(false);
    }

    return <div className="card bg-base-100 mb-4">
        <div className={isCompactMode ? '' : 'card-body p-4'}>
            {!isCompactMode && (<div className="text-lg font-bold">Configuration Details</div>)}
            {name && (<div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Configuration Name</span>
                </label>
                {editMode ? <Fragment><input type="text" placeholder="Provide a name for the Configuration" defaultValue={name}
                    className="input input-bordered w-full"
                    onChange={e => setChanges(current => ({
                        ...current,
                        name: e.target.value
                    }))} />
                    {renderErrors(error, 'name')}</Fragment> : <div className="p-1 text-lg">{name}</div>}
            </div>)}
            {!(isPreviewMode || isCompactMode) && description && (
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Description</span>
                    </label>
                    {editMode ? (
                        <Fragment>
                            <input
                                type="text"
                                placeholder="Provide a name for the Configuration"
                                defaultValue={description}
                                className="input input-bordered w-full"
                                onChange={e => setChanges(current => ({
                                    ...current,
                                    description: e.target.value
                                }))}
                            />
                            {renderErrors(error, 'description')}
                        </Fragment>
                    ) : (
                        <div className="p-1 text-lg">{description}</div>
                    )}
                </div>
            )}

            {!(isPreviewMode || isCompactMode) && (
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Type</span>
                    </label>
                    {editMode ? <Fragment><input type="text" disabled={readOnly} placeholder="Provide a name for the Configuration" defaultValue={type}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                            type: e.target.value
                        }))} />
                        {renderErrors(error, 'type')}</Fragment> : <div className="p-1 text-lg">{type}</div>}
                </div>
            )}
            <div className="flex flex-wrap">
                {key && <div className="w-1/2 md:w-2/5 px-3">
                    <label className="label">
                        <span className="label-text">Key</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Enter Key" defaultValue={key}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                            key: e.target.value
                        }))} />
                        {renderErrors(error, 'key')}</Fragment> : <div className="p-1 text-lg">{key}</div>}
                </div>}
                {value && <div className="w-1/2 md:w-2/5 px-3">
                    <label className="label">
                        <span className="label-text">Value</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Enter value" defaultValue={value}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                            value: e.target.value
                        }))} />
                        {renderErrors(error, 'value')}</Fragment> : <div className="p-1 text-lg">{value}</div>}
                </div>}

            </div>


            {type === "APIAccess" && <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Api Access Type</span>
                </label>
                {editMode ? <Fragment><input type="text" disabled={readOnly} placeholder="Provide a name for the Configuration" defaultValue={apiAccess?.apiAccessType || ''}
                    className="input input-bordered w-full"
                    onChange={e => setChanges(current => ({
                        ...current,
                    }))} />
                    {renderErrors(error, 'apiAccess.apiAccessType')}</Fragment> : <div className="p-1 text-lg">{apiAccess?.apiAccessType || ''}</div>}
            </div>
            }
            {apiAccess.apiAccessType && (apiAccess.apiAccessType === "CIDAAS_OAUTH2" || apiAccess.apiAccessType === "GEN_OAUTH2") && <div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">client id</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Provide a name for the Configuration" defaultValue={apiAccess.oAuthDetails.client_id}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'client_id')}</Fragment> : <div className="p-1 text-lg">{apiAccess.oAuthDetails.client_id}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">client secret</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Provide a name for the Configuration" defaultValue={apiAccess.oAuthDetails.client_secret}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'client_secret')}</Fragment> : <div className="p-1 text-lg">{apiAccess.oAuthDetails.client_secret}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">req_scopes</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Provide a description for the Configuration" defaultValue={apiAccess.oAuthDetails.req_scopes}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'req_scopes')}</Fragment> : <div className="p-1 text-lg">{apiAccess.oAuthDetails.req_scopes}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">wellknownUrl</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Provide a name for the Configuration" defaultValue={apiAccess.oAuthDetails.wellknownUrl}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'apiAccess.oAuthDetails.wellknownUrl')}</Fragment> : <div className="p-1 text-lg">{apiAccess.oAuthDetails.wellknownUrl}</div>}
                </div>
            </div>}


            {apiAccess.apiAccessType && apiAccess.apiAccessType === "TOTP" && <div>


                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">totpkey</span>
                    </label>
                    {editMode ? <Fragment><input type="text" disabled={readOnly} placeholder="Provide a name for the Configuration" defaultValue={apiAccess.totpDetails.totpkey}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'totpkey')}</Fragment> : <div className="p-1 text-lg">{apiAccess.totpDetails.totpkey}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">totp_placeholder</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Provide a name for the Configuration" defaultValue={apiAccess.totpDetails.totp_placeholder}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'totp_placeholder')}</Fragment> : <div className="p-1 text-lg">{apiAccess.totpDetails.totp_placeholder}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">totp_placement</span>
                    </label>
                    {editMode ? <Fragment>

                        <select onChange={e => setChanges(current => ({
                            ...current,
                        }))} defaultValue={apiAccess.totpDetails.totp_placement} className="input input-bordered w-full">
                            <option value="header">Header</option>
                            <option value="query">Query Parameter</option>
                        </select>
                        {renderErrors(error, 'totp_placement')}</Fragment> : <div className="p-1 text-lg">{apiAccess.totpDetails.totp_placement}</div>}
                </div>
            </div>}


            {apiAccess.apiAccessType === "APIKEY" && <div>


                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">apikey</span>
                    </label>
                    {editMode ? <Fragment><input type="text" disabled={readOnly} placeholder="Provide a name for the Configuration" defaultValue={apiAccess.apikeyDetails.apikey}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'apikey')}</Fragment> : <div className="p-1 text-lg">{apiAccess.apikeyDetails.apikey}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">apikey_placeholder</span>
                    </label>
                    {editMode ? <Fragment><input type="text" placeholder="Provide a name for the Configuration" defaultValue={apiAccess.apikeyDetails.apikey_placeholder}
                        className="input input-bordered w-full"
                        onChange={e => setChanges(current => ({
                            ...current,
                        }))} />
                        {renderErrors(error, 'apikey_placeholder')}</Fragment> : <div className="p-1 text-lg">{apiAccess.apikeyDetails.apikey_placeholder}</div>}
                </div>

                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">apikey_placement</span>
                    </label>
                    {editMode ? <Fragment>
                        <select onChange={e => setChanges(current => ({
                            ...current,
                        }))} defaultValue={apiAccess.apikeyDetails.apikey_placement} className="input input-bordered w-full">
                            <option value="header">Header</option>
                            <option value="query">Query Parameter</option>
                        </select>
                        {renderErrors(error, 'apikey_placement')}</Fragment> : <div className="p-1 text-lg">{apiAccess.apikeyDetails.apikey_placement}</div>}
                </div>
            </div>}

            <div className="flex">
                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Active</span>
                    </label>
                    {editMode ? <Fragment><input type="checkbox" className={`toggle toggle-lg ${(changes.active || active) ? 'toggle-success' : ''}`} defaultChecked={active}
                        onChange={e => setChanges(current => ({
                            ...current,
                            active: e.target.checked
                        }))} />
                        {renderErrors(error, 'active')}</Fragment> :
                        <div className="p-1">{active ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>}
                </div>
            </div>


            {!(isPreviewMode || isCompactMode) && (
                <div className="card-actions mt-2 justify-between">
                    <div>{editMode && <button className="btn btn-error" type="button" disabled={executing} onClick={() => setShowDeleteConfirmation(true)}>Delete</button>}</div>
                    <div className="flex justify-end">
                        {!editMode && <button className="btn" type="button" onClick={() => setEditMode(true)}>Edit Configuration</button>}
                        {editMode && <button className="btn mr-2" type="button" onClick={cancelChanges}>Cancel</button>}
                        {editMode && <button className="btn btn-primary" type="button" disabled={executing} onClick={saveChanges}>Save Changes</button>}
                    </div>
                </div>
            )}
            {showDeleteConfirmation && <ConfirmModal title="Delete Configuration" message="Are you sure you want to delete this Configuration?" onConfirm={deleteTransformer} onCancel={() => setShowDeleteConfirmation(false)} />}
        </div>
    </div>
}

export default ConfigurationDetailsCard;