import { useEffect, useRef, useState } from "react";

import axios from "axios";
import get from 'lodash/get';
import filter from 'lodash/filter';
import Editor from '@monaco-editor/react'
import ConfigBuilder from "../../../../../components/ConfigBuilder";
import { renderErrors } from "../../../../../common/formHelpers";
import { IconCheck, IconCirclePlus, IconExclamationCircle, IconLoader3, IconX } from "@tabler/icons-react";
import Loading from "../../../../../components/Loading";
import useNotifications from "../../../../../hooks/useNotifications";
import Modal from "../../../../../components/Modal";
import cloneDeep from 'lodash/cloneDeep';
import ConfirmModal from "../../../../../components/ConfirmModal";
import ExtractorSchedule from "./ExtractorSchedule";
import { generateWorkspacePath } from "../../../../../common/urlHelpers";
import ScriptLanguageSelector from "../../../../../components/ScriptLanguageSelector";
import TestScriptRun from "../../../../../common/TestScriptRun";
import { extractorScriptTemplate, modScriptTemplate } from "../../../../../common/scripts";



const scriptTemplate = `module.exports = function(config) { /* required */
    //add your script here to pull data from your data source.
    let data = ["hello", "world"];
    //always return your data as an array.
    return data;
}`;

const Extractor = ({ sourceId }) => {

    const { addNotification } = useNotifications();
    const editorRef = useRef(null);
    const [original, setOriginal] = useState({});
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [config, setConfig] = useState([{ key: "", value: "", secret: false }]);
    const [script, setScript] = useState(scriptTemplate);
    const [active, setActive] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [expandEditor, setExpandEditor] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const workspacePath = generateWorkspacePath();
    const [language, setLanguage] = useState('javascript');
    const [mainScript, setMainScript] = useState(extractorScriptTemplate);
    const [modScript, setModScript] = useState(modScriptTemplate);
    const [event, setEvent] = useState(null);
    const [noExtractor, setNoExtractor] = useState(true);

    const clearErrors = () => {
        setError({ message: null, details: [] });
    }

    const parseResponsePayload = (data) => {
        setId(data.id);
        setName(data.name);
        setActive(data.active);
        setLanguage(data.language);
        setConfig(cloneDeep(data.config));
        if (data.language === "javascript") {
            setScript(data.script);
        } else {
            setMainScript(data.golang.main);
            setModScript(data.golang.mod)
        }
    };

    const resetFormOnDelete = () => {
        setName("");
        setConfig([{ key: "", value: "", secret: false }]);
        setScript(scriptTemplate);
        setActive(true);
        setId(null);
    }

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);
        const payload = {
            name,
            language,
            active,
            config: filter(config, item => item.key)
        };

        if (language === "javascript") {
            payload.script = script
        } else {
            payload.golang = {
                main: mainScript,
                mod: modScript
            }
        }

        if (id) {
            axios.put(`${workspacePath}/extractors/${id}`, payload).then(response => {
                setOriginal(response.data);
                let message, type
                if (payload.golang) {
                    message = 'Changes has been saved. Build in progress.';
                    type = 'warning'
                } else {
                    message = 'Changes has been saved.';
                    type = 'success'
                }
                addNotification({
                    message: message,
                    type: type
                });
            }).catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while saving the Extractor'),
                    type: 'error'
                });
                setError(err.response.data);
            }).finally(() => {
                setExecuting(false);
                setEditMode(false);
            });
        } else {
            payload.source = {
                id: sourceId
            };
            //create a new extractor
            axios.post(`${workspacePath}/extractors`, payload).then(response => {
                parseResponsePayload(response.data);


                let message, type
                if (payload.golang) {
                    message = 'Changes has been saved. Build in progress.';
                    type = 'warning'
                } else {
                    message = 'Changes has been saved';
                    type = 'success'
                }
                addNotification({
                    message: message,
                    type: type
                });
            }).catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while saving the Extractor'),
                    type: 'error'
                });
                setError(err.response.data);
            }).finally(() => {
                setExecuting(false);
                setEditMode(false);
            });
        }
    }

    const cancelChanges = (e) => {
        e.preventDefault();
        parseResponsePayload(original);
        setEditMode(false);
    }

    const deleteExtractor = (e) => {
        e.preventDefault();
        setExecuting(true);
        axios.delete(`${workspacePath}/extractors/${id}`).then(response => {
            resetFormOnDelete();
            addNotification({
                message: 'Extractor has been deleted',
                type: 'success'
            });
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while deleting Extractor'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        })
    }


    useEffect(() => {
        setExecuting(true);
        axios.get(`${workspacePath}/extractors/sources/${sourceId}`).then(response => {
            if (response.data && response.status === 200) {
                setNoExtractor(false)
                setOriginal(response.data);
                parseResponsePayload(response.data);
            } else {
                setEditMode(true);
            }
        }).catch(err => {
            if (err?.response?.status !== 404) {
                setNoExtractor(false);
                addNotification({
                    title: 'Error',
                    message: 'An error occurred when fetching the Data Extractor for this Source',
                    type: 'error'
                });
            } else {
                setEditMode(true);
            }
        }).finally(() => {
            setExecuting(false);
            setLoading(false);
        });

    }, [addNotification, sourceId, workspacePath])
    if (loading) {
        return <Loading />
    }
    const buildStatus = original.buildStatus
    const isError = buildStatus === 'failed'
    const isPending = buildStatus === 'pending'
    const errorMessage = original.errorMessage


    return <div> {!noExtractor && <div>
        <div className="card bg-base-100 mb-4">
            <div className="card-body p-4">
                <div className="text-lg font-bold bg-base-200 p-2 rounded-md">Data Extractor Details</div>
                <form onSubmit={submit}>
                    <div>
                        <div className="space-y-3 pb-5">

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Extractor Name</span>
                                </label>
                                {editMode ? <>
                                    <input type="text" placeholder="Provide a name for the Extractor" value={name} className="input input-bordered w-full" onChange={e => setName(e.target.value)} />
                                    {renderErrors(error, 'name')}
                                </> : <div className="text-lg font-bold">{name}</div>}

                            </div>
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Configuration</span>
                                </label>
                                <ConfigBuilder config={config} setConfig={setConfig} readOnly={!editMode} />
                                {renderErrors(error, 'config')}
                            </div>

                            <ScriptLanguageSelector
                                script={script || scriptTemplate}
                                setScript={setScript}
                                mainScript={mainScript}
                                setMainScript={setMainScript}
                                modScript={modScript}
                                setModScript={setModScript}
                                scriptTemplate={scriptTemplate}
                                expandEditor={expandEditor}
                                setExpandEditor={setExpandEditor}
                                error={error}
                                addNotification={addNotification}
                                workspacePath={workspacePath}
                                editMode={editMode}
                                selectedLanguage={language}
                                setSelectedLanguage={setLanguage}
                            />

                            <div className="flex">

                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Active</span>
                                    </label>
                                    {editMode ? <><input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={active} onChange={(e) => setActive(e.target.checked)} />
                                        {renderErrors(error, 'active')}
                                    </> : <div className="p-1">{active ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>}
                                </div>
                                {language == 'golang' && <div className="form-control w-full">

                                    <label className="label">
                                        <span className="label-text">Build Status</span>
                                    </label>
                                    <div className="p-1">

                                        {
                                            buildStatus === 'failed' && <div className='tooltip' data-tip="Build Failed" >
                                                <IconExclamationCircle className="text-error" />
                                            </div>
                                        }
                                        {
                                            buildStatus === 'success' && <div className='tooltip' data-tip="Build Success" >
                                                <IconCheck className="text-success" />
                                            </div>
                                        }
                                        {
                                            buildStatus === 'pending' && <div className='tooltip' data-tip="Build in Progress" >
                                                <IconLoader3 className="text-warning" />
                                            </div>
                                        }
                                        {
                                            !buildStatus && <div className='tooltip' data-tip="Build in Progress" >-</div>
                                        }
                                    </div>
                                </div>}
                            </div>
                            <div>
                                {
                                    buildStatus === 'failed' && <div className="mockup-code before:content-none">
                                        {errorMessage.split('\n').map((l, i) => (
                                            <pre data-prefix={i + 1} className="text-error"><code>{l}</code></pre>)
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="mt-2 flex justify-between">
                            <div>{editMode && id && <button className="btn btn-error" type="button" disabled={executing} onClick={() => setShowDeleteConfirmation(true)}>Delete</button>}</div>
                            <div className="flex justify-end">
                                {!editMode && <button className="btn mr-2" type="button" onClick={() => setEvent({ id: id, body: {}, type: "extractors" })}> Run Script</button>}
                                {!editMode && <button className="btn" type="button" onClick={() => setEditMode(true)}>Edit Extractor</button>}
                                {editMode && id && <button className="btn mr-2" type="button" onClick={cancelChanges}>Cancel</button>}
                                {editMode && <button disabled={executing} type="submit" className="btn btn-primary">Save Changes</button>}
                            </div>
                        </div>
                    </div>
                </form>
                {showDeleteConfirmation && <ConfirmModal title="Delete Extractor" message="Are you sure you want to delete this Extractor?" onConfirm={deleteExtractor} onCancel={() => setShowDeleteConfirmation(false)} />}
                {expandEditor && <Modal large={true} title="Script" onCancel={() => setExpandEditor(false)}>
                    <>
                        <div className="px-6 pb-4">
                            <ScriptLanguageSelector
                                script={script}
                                setScript={setScript}
                                mainScript={extractorScriptTemplate}
                                setMainScript={setMainScript}
                                modScript={modScriptTemplate}
                                setModScript={setModScript}
                                expandEditor={expandEditor}
                                setExpandEditor={setExpandEditor}
                                error={error}
                                addNotification={addNotification}
                                workspacePath={workspacePath}
                                editMode={editMode}
                                selectedLanguage={language}
                                setSelectedLanguage={setLanguage}
                            />
                        </div>
                        <div className="bg-base-200 px-4 py-3 justify-end sm:px-6 flex">
                            <button className="btn" onClick={() => setExpandEditor(false)}>Collapse</button>
                        </div>
                    </>
                </Modal>}
            </div></div>
        {id && <ExtractorSchedule extractorId={id} />}
        {event && <Modal title={"Run Script of " + name} onCancel={() => setEvent(null)}>
            <TestScriptRun event={event} onCancel={() => setEvent(null)} onSendEvent={() => { }} />
        </Modal>}
    </div>}

        {noExtractor && <div className="flex flex-col justify-center my-5">
            <div className="flex justify-center mb-4 text-primary">
                <img alt="Create new Data Extractor" src="/undraw/welcoming.svg" className="w-3/6 max-w-3/5" style={{ maxHeight: '60vh' }} />
            </div>
            <div className="flex justify-center mb-4 text-center">
                There is no associated Data Extractor. Click the button below to start configuring a new one
            </div>
            <div className="flex justify-center">
                <button className="btn" onClick={() => setNoExtractor(false)}><IconCirclePlus size={36} />New Data Extractor </button>
            </div>
        </div>}
    </div>

}

export default Extractor;