export const generateApiCode = (httpMethod, url, codeTypes = ["curl"], apiKeyConfig = null)=> {
    const generatedCode = {};
    // let postmanSchema = null;

    let queryString = "";
    let headerString = `-H "Content-Type: application/json"`;

    // Handling API Key logic
    if (apiKeyConfig && apiKeyConfig.key && apiKeyConfig.value) {
        if (apiKeyConfig.placement === "query") {
            queryString = `${url.includes('?') ? '&' : '?'}${apiKeyConfig.key}=${apiKeyConfig.value}`;
        } else if (apiKeyConfig.placement === "header") {
            headerString += ` -H "${apiKeyConfig.key}: ${apiKeyConfig.value}"`;
        }
    }

    // Curl generation with API key
    if (codeTypes.includes("curl")) {
        generatedCode.curl = `curl -X ${httpMethod.toUpperCase()} "${url}${queryString}" ${headerString} -d '{"key": "value"}'`;
    }

    // Wget generation with API key
    if (codeTypes.includes("wget")) {
        generatedCode.wget = `wget --method=${httpMethod.toUpperCase()} --header="Content-Type: application/json" ${apiKeyConfig && apiKeyConfig.placement === "header" ? `--header="${apiKeyConfig.key}: ${apiKeyConfig.value}" ` : ""} --body-data='{"key": "value"}' "${url}${queryString}"`;
    }

    // Generate Postman Schema only if 'postman' is in codeTypes
    if (codeTypes.includes("postman")) {
        generatedCode.postman = {
            info: {
                name: "Generated API Request",
                schema: "https://schema.getpostman.com/json/collection/v2.1.0/collection.json"
            },
            item: [
                {
                    name: "API Request",
                    request: {
                        method: httpMethod.toUpperCase(),
                        url: {
                            raw: `${url}${queryString}`,
                            host: [url.split("://")[1]],
                            protocol: url.split("://")[0].replace(":", ""),
                            query: apiKeyConfig && apiKeyConfig.placement === "query"
                                ? [{ key: apiKeyConfig.key, value: apiKeyConfig.value }]
                                : []
                        },
                        header: [
                            {
                                key: "Content-Type",
                                value: "application/json"
                            },
                            ...(apiKeyConfig && apiKeyConfig.placement === "header"
                                ? [{ key: apiKeyConfig.key, value: apiKeyConfig.value }]
                                : [])
                        ],
                        body: {
                            mode: "raw",
                            raw: JSON.stringify({ key: "value" }, null, 2)
                        }
                    }
                }
            ]
        };
    }

    return generatedCode
}

export const availableScriptsInGenerator = [
    "curl",
    "wget",
    // "postman"
]
// Example Usage with Postman Schema
// const { postmanSchema, generatedCode } = generateApiCode(
//     "POST",
//     "https://api.example.com/data",
//     ["curl", "wget", "postman"],
//     {
//         key: "apiKey",
//         value: "12345",
//         placement: "header"
//     }
// );

// // Print results
// console.log("Generated Code:", generatedCode);
// if (postmanSchema) {
//     console.log("Postman Schema:", JSON.stringify(postmanSchema, null, 2));
// }
