import React, { Fragment, useEffect, useState } from 'react';
import { breadcrumbs } from '..';
import PageTitle from '../../../components/Nav/PageTitle';
import { renderErrors } from '../../../common/formHelpers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { generateWorkspacePath } from '../../../common/urlHelpers';
import ContextHelp from '../../../components/ContextHelp';
import TimePickerPopup from '../../../components/Calendar/TimePicker';
import ExpandingTextarea from '../../../components/TextArea/ExpandableTextArea';
import axios from 'axios';
import useNotifications from '../../../hooks/useNotifications';
import { relativeTimeToSeconds, secondsToRelativeTime } from '../../../common/dateHelpers';
import { get } from 'lodash';

const NewGlobalVariable = () => {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [error, setError] = useState({ message: null, details: [] });
  const [expiry, setExpiry] = useState(false);
  const [executing, setExecuting] = useState(false);

  var location = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const workspacePath = generateWorkspacePath();

  const clearErrors = () => {
    setError({ message: null, details: [] });
  }

  const changeKey = (e) => {
    setKey(e.target.value);
    clearErrors();
    e.preventDefault();
  }

  const resetAll = () => {
    setKey('');
    setValue('');
    setExpiry(false);
  }

  const submit = (e) => {
    e.preventDefault();
    clearErrors();
    setExecuting(true);

    const payload = {
      key: key,
      value: value,
      expiry: 0
    };
    if (expiry) {
      payload['expiry'] = relativeTimeToSeconds(expiry);
    }
    axios.post(`${workspacePath}/globalvariable`, payload)
      .then(response => {
        resetAll();
        const id = response.data.id;
        addNotification({
          message: 'Global variable has been created',
          type: 'success'
        });
        resetAll()
        navigate('/global-variables/' + id);
      })
      .catch(err => {
        let message = 'An error occurred while creating Global Variable';
        if (err.response && err.response.status === 409) {
          message = "A Global Variable with this key already exists. Please try with a different key.";
        }
        addNotification({
          message: err.response ? err.response.data.message || message : message,
          type: 'error'
        });
        if (err.response) {
          setError(err.response.data);
        }
      })
      .finally(() => {
        setExecuting(false);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cloneFrom = queryParams.get("cloneFrom");
    if (cloneFrom) {
      setExecuting(true);
      addNotification({
        type: 'info',
        message: 'Fetching data...'
      });
      axios.get(`${workspacePath}/globalvariable/${cloneFrom}`)
        .then(response => {
          setKey(`${response.data.key}_clone`);
          setValue(JSON.stringify(response.data.value));
          if (response.data.expiry) {
            setExpiry(secondsToRelativeTime(response.data.expiry));

          }
          addNotification({
            type: "success",
            message: "Global Variable copied successfully."
          });
        })
        .catch(err => {
          addNotification({
            message: get(err, 'response.data.message', 'An error occurred while fetching Global Variable'),
            type: 'error'
          });
        })
        .finally(() => {
          setExecuting(false);
        });
    }
  }, [location]);

  return (
    <Fragment>
      <PageTitle itemKey="globalvariables" breadcrumbs={breadcrumbs} />
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={submit}>
            <div className="shadow-inner sm:overflow-hidden sm:rounded-md">
              <div className="space-y-3 px-4 py-5 sm:p-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Key</span>
                  </label>
                  <input type="text" placeholder="Provide a name for the Global Variable" value={key} className="input input-bordered w-full" onChange={changeKey} />
                  {renderErrors(error, 'key')}
                </div>
              </div>
              {/* <div className="space-y-3 px-4 py-5 sm:p-6">
                <div className="form-control w-1/2">
                  <label className="label">
                    <span className="label-text">Data Type</span>
                  </label>
                  <select
                    className="input input-bordered w-full select select-bordered"
                    id="dataType"
                    value={dataType}
                    onChange={changeDataType}
                  >
                    <option value="" disabled>Choose a data type</option>
                    {dataTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                  {renderErrors(error, 'dataType')}
                </div>
              </div> */}
              <div className="space-y-3 px-4 py-5 sm:p-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Value</span>
                  </label>
                  <ExpandingTextarea value={value} setValue={setValue} placeholder='Provide value' />
                  {renderErrors(error, 'name')}
                </div>
              </div>
              <div className="space-y-3 px-4 py-5 sm:p-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Expiry</span>
                  </label>
                  <div className='py-3 flex justify-start gap-12'>
                    <input type="checkbox" className={`toggle toggle-lg ${expiry ? 'toggle-success' : ''}`} checked={expiry} onChange={(e) => setExpiry(e.target.checked)} />
                    {expiry && <TimePickerPopup relativeTime={expiry} setRelativeTime={setExpiry} />}
                    {renderErrors(error, 'name')}
                  </div>
                </div>
              </div>
              <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                <Link to="/globalvariables" className="btn btn-ghost mr-2">Cancel</Link>
                <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <div className="md:col-span-1">
          <ContextHelp page="create-global-variable" />
        </div>
      </div>
    </Fragment>
  );
};

export default NewGlobalVariable;