import { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/Nav/PageTitle";
import { IconCirclePlus, IconSearch, IconUpload } from '@tabler/icons-react';
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import get from "lodash/get";
import useNotifications from "../../hooks/useNotifications";
import Loading from "../../components/Loading";
import FileDrop from "../../components/FileUpload";

import PipelineList from "./PipelineList";
import useStatusFilter from "../../hooks/useStatusFilter";
import { generateWorkspacePath } from "../../common/urlHelpers";
import PipelineFilter from "./PipelineFilter";

const Pipelines = () => {

    const { addNotification } = useNotifications();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    const [status, setStatus] = useState(searchParams.has('status') ? searchParams.get('status') : null);

    useStatusFilter({ status });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [showFileDrop, setShowFileDrop] = useState(false);
    const [file, setFile] = useState(null);
    const workspacePath = generateWorkspacePath();
    useEffect(() => {
        setPage(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    }, [searchParams]);

    useEffect(() => {
        searchPipelines();
    }, [page, status, addNotification]);

    const searchPipelines = (event) => {
        if (event && event.type === "click") {
            setPage(0);
        }
        let url = `${workspacePath}/pipelines?page=${page}&size=15`;
        if (searchQuery) {
            url = url + `&name=${searchQuery}`;
        }
        const filters = [];
        if (status) {
            filters.push(`&status=${status}`);
        }

        if (filters.length > 0) {
            url += `&filters=${filters.join()}`;
        }

        axios.get(url).then(response => {
            setTotalCount(response.data && response.data.count ? response.data.count : 0);
            setData(response.data && response.data.list ? response.data.list : []);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while fetching Pipelines'),
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const onUpload = (id) => {
        const formData = new FormData();
        formData.append('file', file);
        
        axios.post(`${workspacePath}/pipelines/${data.id}/import`, formData)
            .then(response => {
                addNotification({
                    message: 'File uploaded successfully',
                    type: 'success'
                })
                console.log('File uploaded successfully:', response.data);
                setFile(null);
                setShowFileDrop(false);
                searchPipelines();

            })
            .catch(error => {
                if (error.response) {
                    console.error('Error uploading file:', error.response.status, error.response.data);
                } else if (error.request) {
                    console.error('Error uploading file: No response received', error.request);
                } else {
                    console.error('Error uploading file:', error.message);
                }
                addNotification({
                    message: 'Error uploading file',
                    type: 'error'
                })
            });

    }
    return (
        <Fragment>
            <PageTitle itemKey="pipelines">
                <button onClick={() => { setShowFileDrop(true) }} className="btn btn-primary btn-sm md:btn-md mr-2">
                    <IconUpload size={24} />
                    Import Pipeline from file
                </button>
                <Link to="/pipelines/new" className="btn btn-primary btn-sm md:btn-md">
                    <IconCirclePlus size={24} />
                    New Pipeline
                </Link>
            </PageTitle>

            {loading ? (
                <Loading />
            ) : (
                <div>
                    <div className="flex justify-between items-center mb-4">
                        <div className="input-group">
                            <input type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search Pipelines by name" className="input input-bordered w-full" />
                            <button className="btn btn-square tooltip p-3" data-tip="Search Pipeline" onClick={e => { searchPipelines(e) }}>
                                <IconSearch width={24} />
                            </button>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <PipelineFilter status={status} setStatus={setStatus} />
                        <PipelineList data={data} totalCount={totalCount} status={status} />
                    </div>
                </div>
            )}
            {showFileDrop && <FileDrop onClose={() => setShowFileDrop(false)} onUpload={onUpload} setFile={setFile} file={file} />}
        </Fragment>
    );
};

export const breadcrumbs = [{ label: "Pipelines", url: "/pipelines" }];

export default Pipelines;