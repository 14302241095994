import { Fragment, useEffect, useRef, useState } from "react";
import { generateApiCode, availableScriptsInGenerator } from '../../../common/scriptGenerator'
import { createPopper } from '@popperjs/core';
import useNotifications from '../../../hooks/useNotifications';
import { IconClipboard } from "@tabler/icons-react";


export const HTTPMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];

const CopyCode = ({ functionURL, httpMethod, accessDetails }) => {


    const copyButtonRef = useRef(null);
    const copyMenuRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const { addNotification } = useNotifications();

    const copyToClipboard = (key, message) => {
        navigator.clipboard.writeText(key).then(() => {
            addNotification({
                message,
                type: 'info'
            })
        }).catch((error) => {
            addNotification({
                message: "Error writing to clipboard"
            })
        });
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                copyMenuRef.current &&
                !copyMenuRef.current.contains(event.target) &&
                copyButtonRef.current !== event.target
            ) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleApiCode = (e, codeType) => {
        e.preventDefault();
        switch (codeType) {
            case "url":
                copyToClipboard(functionURL, "URL has been copied to Clipboard")
                break;
            default:
                const code = generateApiCode(httpMethod, functionURL, [codeType], accessDetails)
                copyToClipboard((code[codeType]).toString(), "Code has been copied to Clipboard")
                break;
        }
        setShowMenu(false)
    }

    const toggleCopyMenu = () => {
        setShowMenu((prev) => !prev);
        if (showMenu) {
            createPopper(copyButtonRef.current, copyMenuRef.current, {
                placement: "bottom-start",
                modifiers: [
                    {
                        name: "offset",
                        options: { offset: [0, 8] },
                    },
                ],

            });
        }
    };
    return (
        <div className="relative inline-block">
            <button title="Copy to Clipboard" className="ml-2 btn btn-ghost btn-xs"
                ref={copyButtonRef}
                onClick={(e) => {
                    e.preventDefault();
                    toggleCopyMenu();
                }}
            >
                <IconClipboard size={16} /></button>

            {showMenu && <div ref={copyMenuRef} className="menu bg-base-200 rounded-box w-56 p-2 shadow-lg z-50 border border-gray-200 absolute">
                <ul class="menu bg-base-200 rounded-box w-56">
                    <li><a onClick={(e) => handleApiCode(e, 'url')}>URL</a></li>
                    {availableScriptsInGenerator.map(code => (
                        <li><a onClick={(e) => handleApiCode(e, code)}>{code}</a></li>
                    ))}


                </ul>
            </div>}
        </div>
    )
}

export default CopyCode