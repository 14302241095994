import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/Nav/PageTitle';
import { Link, useSearchParams } from 'react-router-dom';
import { IconCirclePlus, IconSearch } from '@tabler/icons-react';
import useNotifications from '../../hooks/useNotifications';
import axios from 'axios';
import { get } from 'lodash';
import Pagination from '../../components/Pagination';
import { generateWorkspacePath } from '../../common/urlHelpers';
import Loading from '../../components/Loading';
import { timeAgo } from '../../common/dateHelpers';

const GlobalVariables = () => {
    const { addNotification } = useNotifications();
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    const searchGlobalVariables = () => {
        const workspacePath = generateWorkspacePath();
        let url = `${workspacePath}/globalvariable?page=${page}&size=15`;
        if (searchQuery) {
            url = url + `&name=${searchQuery}`;
        }
        axios.get(url).then(response => {
            setTotalCount(response.data && response.data.count ? response.data.count : 0);
            setData(response.data && response.data.list ? response.data.list : []);
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while fetching Global Variables.'),
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        setPage(searchParams.has('page') ? searchParams.get('page') * 1 : 0);
    }, [searchParams]);

    useEffect(() => {
        searchGlobalVariables()
    }, [page, addNotification]);

    const renderNoConfigurations = () => {
        return <div className="flex flex-col justify-center my-5">
            <div className="flex justify-center mb-4 text-primary">
                <img alt="Create new Configuration" src="/undraw/coding.svg" className="w-3/6 max-w-3/5" />
            </div>
            <div className="flex justify-center mb-4 text-center">
                There are no Variables. Click the button below to add a new Global Variable.
            </div>
            <div className="flex justify-center">
                <Link to="/configurations/new" className="btn btn-md md:btn-lg"><IconCirclePlus size={36} />New Variable</Link>
            </div>
        </div>
    }

    return (
        <Fragment>
            <PageTitle itemKey="globalvariables">
                <Link to="/global-variables/new" className="btn btn-primary btn-sm md:btn-md"><IconCirclePlus size={24} />New Variable</Link>
            </PageTitle>
            {loading ? <Loading /> :
                (<div className="overflow-x-auto">
                    <div>
                        <div className="flex justify-between items-center mb-4">
                            <div className="input-group">
                                <input type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search Global Variables by name" className="input input-bordered w-full" />
                                <button className="btn btn-square tooltip p-3" data-tip="Search Variables" onClick={e => { searchGlobalVariables(e) }}>
                                    <IconSearch width={24} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-container">
                        {totalCount > 0 ? <table className="table data-table table-zebra w-full">
                            <thead>
                                <tr>
                                    <th className="bg-base-300 text-left w-1/3">Key</th>
                                    <th className="bg-base-300 text-left  w-1/3">Value</th>
                                    {/* <th className="bg-base-300 text-left  w-1/9">Data Type</th> */}
                                    <th className="bg-base-300 text-left w-1/9">Created</th>
                                    <th className="bg-base-300 text-left w-1/9">Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(variable => <tr key={variable.id}>
                                    <td className="whitespace-pre-wrap break-words"><Link className="link link-hover" to={`/global-variables/${variable.id}`}>{variable.key}</Link></td>
                                    <td className="whitespace-pre-wrap break-words">{JSON.stringify(variable.value)}</td>
                                    {/* <td className="whitespace-pre-wrap break-words">{variable.dataType}</td> */}
                                    <td className="whitespace-pre-wrap break-words">{timeAgo(variable.createdAt)}</td>
                                    <td className="whitespace-pre-wrap break-words">{timeAgo(variable.updatedAt)}</td>
                                </tr>)}
                            </tbody>
                        </table> : renderNoConfigurations()}
                    </div>
                    {totalCount > 0 && <Pagination totalCount={totalCount} />}
                </div>)
            }
        </Fragment>
    );
};
export const breadcrumbs = [{ label: 'Global Variables', url: '/configurations' }];

export default GlobalVariables;