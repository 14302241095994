import { useEffect, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import { ThemeContextProvider } from "./context/ThemeContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import NavBar from "./components/Nav/NavBar";
import LeftNav from "./components/Nav/LeftNav";
import NotificationList from "./components/NotificationList";
import Loading from "./components/Loading";
import './App.scss';
import { InitializeCidaas, CheckForToken } from "./cidaas";
import useLeftNavCollapse from "./hooks/useLeftNavCollapse";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [renderMainContent, setRenderMainContent] = useState(true);
  const { isDrawerOpen, toggleDrawer } = useLeftNavCollapse()

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get("/mgmt-srv/sso/clientid");
        if (response && response.data) {
          InitializeCidaas(response.data.issuer, response.data.client_id);
          const isTokenValid = await CheckForToken();
          setLoading(!isTokenValid);
        }
      } catch (err) {
        console.log(err);
      }
    };

    initialize();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeContextProvider>
      <NotificationContextProvider>
        <div className="drawer drawer-mobile lg:drawer-open">
          <input id="page-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content">
            <NavBar isDrawerOpen={isDrawerOpen} setRenderMainContent={setRenderMainContent} />
            <div className="p-4 pt-6">
              {!renderMainContent && <Outlet />}
            </div>
            <NotificationList />
          </div>
          <LeftNav isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </div>
        <div id="modal"></div>
      </NotificationContextProvider>
    </ThemeContextProvider>
  );
}
