import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { generateWorkspacePath } from '../../../../common/urlHelpers';
import useNotifications from '../../../../hooks/useNotifications';
import Loading from '../../../../components/Loading';
import { Link, useNavigate } from 'react-router-dom';
import ItemSelectorModal from '../../../../components/ItemSelectorModal';
import { timeAgo } from '../../../../common/dateHelpers';
import { IconTrashX } from '@tabler/icons-react';

const PipelineGlobalVariables = ({ pipeline }) => {
    const [globalVariables, setGlobalVariables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [showGlobalVarSelector, setShowGlobalVarSelector] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    // const [newVariable, setNewVariable] = useState({ key: '', value: '' });
    const { addNotification } = useNotifications();
    const workspacePath = generateWorkspacePath();
    const navigate = useNavigate();

    useEffect(() => {
        fetchGlobalVariables();
    }, []);

    const globalVariablePath = `${workspacePath}/globalvariable`
    const fetchGlobalVariables = async () => {
        await axios.get(`${workspacePath}/pipelines/${pipeline.id}/globalvariable`)
            .then(response => {
                console.log(response)
                setGlobalVariables(response.data.list);
                setTotalCount(response.data.count);
                setLoading(false);
            })
            .catch(error => {
                addNotification({
                    type: 'error',
                    message: 'Failed to fetch global variables'
                });
                setLoading(false);
            })
    };

    const addGlobalVariable = async (id) => {

        await axios.post(`${workspacePath}/pipelines/${pipeline.id}/globalvariable/${id}`)
            .then(res => {
                addNotification({
                    type: 'success',
                    message: 'Global variable added successfully'
                });
                // setNewVariable('');
                setLoading(true)
                fetchGlobalVariables();
            })
            .catch(error => {
                addNotification({
                    type: 'error',
                    message: 'Failed to add global variable'
                });
            })
    };

    const deleteGlobalVariable = async (gVarId) => {
        await axios.delete(`${workspacePath}/pipelines/${pipeline.id}/globalvariable/${gVarId}`)
            .then(r => {
                addNotification({
                    type: 'success',
                    message: 'Global variable deleted successfully'
                });
                fetchGlobalVariables();

            })
            .catch(error => {
                addNotification({
                    type: 'error',
                    message: 'Failed to delete global variable'
                });
            })
    };

    const createGlobalVariable = () => {
        navigate("/global-variables/new")
    }

    const onSelectGlobalVariable = (gVar) => {
        // setNewVariable(gVar.id)
        setShowGlobalVarSelector(false)
        addGlobalVariable(gVar.id)
    }
    return (
        <div className="grid grid-flow-row-dense grid-cols-1 xl:grid-cols-8 gap-4">
            <div className="xl:col-span-6">
                <div className="card bg-base-100 mb-4">
                    <div className="card-body p-4">
                        <div className="text-lg font-bold bg-base-200 p-2 rounded-md">Global Variables</div>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                {totalCount > 0 ?
                                    <table className="table data-table table-zebra w-full">
                                        <thead>
                                            <tr>
                                                <th className="bg-base-300 text-left w-1/4">Key</th>
                                                <th className="bg-base-300 text-left  w-1/4">Value</th>
                                                {/* <th className="bg-base-300 text-left  w-1/9">Data Type</th> */}
                                                <th className="bg-base-300 text-left w-1/9">Created</th>
                                                <th className="bg-base-300 text-left w-1/9">Updated</th>
                                                <th className="bg-base-300 text-left w-1/9">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {globalVariables.map(variable => <tr key={variable.id}>
                                                <td className="whitespace-pre-wrap break-words"><Link className="link link-hover" to={`/global-variables/${variable.id}`}>{variable.key}</Link></td>
                                                <td className="whitespace-pre-wrap break-words">{JSON.stringify(variable.value)}</td>
                                                {/* <td className="whitespace-pre-wrap break-words">{variable.dataType}</td> */}
                                                <td className="whitespace-pre-wrap break-words">{timeAgo(variable.createdAt)}</td>
                                                <td className="whitespace-pre-wrap break-words">{timeAgo(variable.updatedAt)}</td>
                                                <td className="whitespace-pre-wrap break-words">
                                                    <button onClick={(e) => deleteGlobalVariable(variable.id)} className='btn p-0 m-0 min-h-0 h-auto btn-error btn-outline border-none' >
                                                        <IconTrashX />
                                                    </button>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                    :
                                    <span>No global variables are added to this pipeline.</span>}
                                <div className="card-actions mt-4 justify-between">
                                    <button onClick={createGlobalVariable} className="btn btn-primary">Create Global Variable</button>
                                    <button onClick={() => { setShowGlobalVarSelector(true) }} className="btn btn-primary">Add Variable</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="hidden xl:col-span-2 xl:block m-2">
                <div className="card bg-base-100 shadow">
                    <div className="card-body">
                        <h2 className="card-title text-info">Global Variables</h2>
                        <div>
                            <p className="mt-4">Global variables are key-value pairs that can be used across your pipeline.</p>
                            <p className="mt-4">They provide a convenient way to store and access data that needs to be shared between different stages of your pipeline.</p>
                            <p className="mt-4">Use them for configuration settings, shared constants, or any other data that needs to be globally accessible within your pipeline.</p>
                        </div>
                    </div>
                </div>
            </div>
            {showGlobalVarSelector && <ItemSelectorModal url={globalVariablePath} title="Select a Global Variable" cols={[{ "label": "Key", "datacss": "text-left", "css": "w-1/2", "name": "key" }, { "label": "Value", "datacss": "", "css": "text-center", "name": "value" }, { "label": "Expiry", "datacss": "", "css": "text-center", "name": "expiry" }]}
                onCancel={() => setShowGlobalVarSelector(false)} onSelect={onSelectGlobalVariable} />}
        </div>
    );
};

export default PipelineGlobalVariables;