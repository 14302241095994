import { IconCheck, IconX } from '@tabler/icons-react';
import { Fragment, useState, useRef, useEffect } from "react";
import useNotifications from '../../../hooks/useNotifications';
import { renderErrors } from "../../../common/formHelpers";
import get from 'lodash/get';
import keys from 'lodash/keys';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import { generateWorkspacePath } from '../../../common/urlHelpers';

const GlobalVariableDetailsCard = (props) => {
    const { data: { id, key, value, dataType, workspaceId, createdAt, updatedAt }, isPreviewMode, isCompactMode, onUpdate } = props;
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [changes, setChanges] = useState({});
    const [executing, setExecuting] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const saveChanges = (e) => {
        e.preventDefault();

        let payload = {
            key: changes.key || key,
            value: changes.value || value,
            dataType: changes.dataType || dataType
        };
        setExecuting(true);
        const workspacePath = generateWorkspacePath();

        const options = {
            method: 'PUT',
            url: `${workspacePath}/globalvariable/${id}`,
            headers: { 'Content-Type': 'application/json' },
            data: payload
        };

        axios.request(options)
            .then(response => {
                onUpdate(response.data);
                setEditMode(false);
                addNotification({
                    message: 'Global Variable has been updated',
                    type: 'success'
                });
            })
            .catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while updating Global Variable'),
                    type: 'error'
                });
                setError(err.response.data);
            })
            .finally(() => {
                setExecuting(false);
            });
    }

    const deleteGlobalVariable = (e) => {
        e.preventDefault();
        setExecuting(true);
        const workspacePath = generateWorkspacePath();

        const options = {
            method: 'DELETE',
            url: `${workspacePath}/globalvariable/${id}`,
            headers: { 'Content-Type': 'application/json' }
        };

        axios.request(options)
            .then(response => {
                addNotification({
                    message: 'Global Variable has been deleted',
                    type: 'success'
                });
                navigate('/global-variables');
            })
            .catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while deleting Global Variable'),
                    type: 'error'
                });
                setError(err.response.data);
            })
            .finally(() => {
                setExecuting(false);
                setShowDeleteConfirmation(false);
            });
    }

    useEffect(() => {
        setChanges(current => ({
            ...current,
        }));
    }, []);

    const cancelChanges = (e) => {
        e.preventDefault();
        setChanges({});
        setEditMode(false);
    }

    return <div className="card bg-base-100 mb-4">
        <div className={isCompactMode ? '' : 'card-body p-4'}>
            {!isCompactMode && (<div className="text-lg font-bold">Global Variable Details</div>)}
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Key</span>
                </label>
                {editMode ? <Fragment><input type="text" placeholder="Enter Key" defaultValue={key}
                    className="input input-bordered w-full"
                    onChange={e => setChanges(current => ({
                        ...current,
                        key: e.target.value
                    }))} />
                    {renderErrors(error, 'key')}</Fragment> : <div className="p-1 text-lg">{key}</div>}
            </div>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Value</span>
                </label>
                {editMode ? <Fragment><input type="text" placeholder="Enter Value" defaultValue={value}
                    className="input input-bordered w-full"
                    onChange={e => setChanges(current => ({
                        ...current,
                        value: e.target.value
                    }))} />
                    {renderErrors(error, 'value')}</Fragment> : <div className="p-1 text-lg">{value}</div>}
            </div>
            {/* <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Data Type</span>
                </label>
                {editMode ? <Fragment><input type="text" placeholder="Enter Data Type" defaultValue={dataType}
                    className="input input-bordered w-full"
                    onChange={e => setChanges(current => ({
                        ...current,
                        dataType: e.target.value
                    }))} />
                    {renderErrors(error, 'dataType')}</Fragment> : <div className="p-1 text-lg">{dataType}</div>}
            </div> */}
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Created At</span>
                </label>
                <div className="p-1 text-lg">{new Date(createdAt).toLocaleString()}</div>
            </div>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Updated At</span>
                </label>
                <div className="p-1 text-lg">{new Date(updatedAt).toLocaleString()}</div>
            </div>

            {!(isPreviewMode || isCompactMode) && (
                <div className="card-actions mt-2 justify-between">
                    <div>{editMode && <button className="btn btn-error" type="button" disabled={executing} onClick={() => setShowDeleteConfirmation(true)}>Delete</button>}</div>
                    <div className="flex justify-end">
                        {!editMode && <button className="btn" type="button" onClick={() => setEditMode(true)}>Edit Global Variable</button>}
                        {editMode && <button className="btn mr-2" type="button" onClick={cancelChanges}>Cancel</button>}
                        {editMode && <button className="btn btn-primary" type="button" disabled={executing} onClick={saveChanges}>Save Changes</button>}
                    </div>
                </div>
            )}
            {showDeleteConfirmation && <ConfirmModal title="Delete Global Variable" message="Are you sure you want to delete this Global Variable?" onConfirm={deleteGlobalVariable} onCancel={() => setShowDeleteConfirmation(false)} />}
        </div>
    </div>
}

export default GlobalVariableDetailsCard;