import React, { useRef, useEffect } from "react";

const ExpandingTextarea = ({ 
  value, 
  setValue, 
  maxRows = 20, 
  placeholder = "Type here...",
  className = "" 
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = "auto"; // Reset height for recalculation
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10) || 16; // Get line height
      const maxHeight = lineHeight * maxRows; // Calculate max height based on rows

      if (textarea.scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`; // Set height to max
        textarea.style.overflowY = "auto"; // Enable vertical scrolling
      } else {
        textarea.style.height = `${textarea.scrollHeight}px`; // Dynamically adjust height
        textarea.style.overflowY = "hidden"; // Hide vertical scroll if content fits
      }
    }
  }, [value, maxRows]);

  return (
    <textarea
      ref={textareaRef}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {e.preventDefault(); setValue(e.target.value)}}
      className={`textarea textarea-bordered w-full resize-none ${className}`} 
      style={{ overflow: "hidden" }} 
      rows={1} 
    />
  );
};

export default ExpandingTextarea;
