// src/components/Modal.js
import { IconFiles, IconJson, IconUpload } from "@tabler/icons-react";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";

const FileDrop = ({ file, setFile, onClose, onUpload }) => {

  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".json", // File types
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
    >
      <div className="modal modal-open">
        <div className="modal-box w-120">
          <div className="flex items-between">
          <h2 className="text-2xl mb-4">Upload or Drop File</h2>
          <button onClick={onClose} className="btn btn-sm btn-circle absolute right-2 top-2">✕</button>}
          </div>
          <div
            {...getRootProps()}
            className="border-dashed border-radius-1 border-2 border-blue-600 p-8 text-center mb-4 rounded-2xl"
          >
            <div className="items-center justify-center flex">
              {/* <IconJson size={36} />
              <IconFiles size={36} /> */}
              <IconUpload size={36} />
            </div>
            <input {...getInputProps()} />
            <p className="text-lg">Drag & drop your file here, or click to select file</p>
          </div>
          {file && (
            <div>
              <p className="font-medium">Selected File: {file.name}</p>
            </div>
          )}
          <div className="modal-action ml-auto">
            <button className="btn btn-primary" onClick={onUpload}>
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FileDrop;
